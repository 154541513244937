import React from 'react'
import { navigateTo } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <div className="contact" id="contact">
        <h2>Get in Touch</h2>
        <form
          name="contact"
          action="/thanks"
          method="POST"
          netlify-honeypot="bot-field"
          data-netlify="true"
          onSubmit={this.handleSubmit}
        >
          <p>
            <label>
              <span class="sr-only">Your Name:</span>
              <input
                required
                type="text"
                name="name"
                placeholder="Name"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <span class="sr-only">Your Email:</span>
              <input
                required
                type="email"
                name="email"
                placeholder="Email"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <span class="sr-only">Your Organization:</span>
              <input
                required
                type="text"
                name="organization"
                placeholder="Organization"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p>
            <label>
              <span class="sr-only">Your Project:</span>
              <textarea
                name="message"
                placeholder="Tell us about your project"
                onChange={this.handleChange}
              />
            </label>
          </p>
          <p class="hidden">
    	    <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
  	  </p>
	  <p>
            <button type="submit">Send</button>
          </p>
        </form>
      </div>
    )
  }
}
