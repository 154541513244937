import React from 'react'

import Layout from '../components/layout'
import Contact from '../components/contact'

const IndexPage = () => (
  <Layout>
    <h1 class="sr-only">Contact</h1>
    <Contact />
  </Layout>
)

export default IndexPage
